import { checkToken, getLoggedInUser } from "../../utilities/users-service";
import { Link } from "react-router-dom";
import "./UserShowPage.css"

export default function UserShowPage ({user, setUser}) {

    // async function handleCheckToken() {
    //     const expDate = await checkToken()
    //     console.log(expDate)
    // }

    return (
        <div className="user-ctr">
            <div className="banner">
                <h1>My Profile</h1>
            </div>
            <div className="user-information-ctr flex-ctr-ctr-col">
                <br></br>
                <h2>{user.name}</h2>
                <h4>{user.email}</h4>
                <Link to={`/aki/user/edit`} >
                    <button><h3>Edit profile information</h3></button>
                </Link>
            </div> 
        </div>
      )
    }