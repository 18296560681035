import { useState } from 'react';
import * as usersService from "../../utilities/users-service"
import "./LoginForm.css"
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { BsFillPersonFill } from "react-icons/bs"
import { signUp } from '../../utilities/users-service'
import Loader from '../Loader/Loader';


export default function LoginForm({ setUser }) {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  function handleChange(evt) {
    setCredentials({ ...credentials, [evt.target.name]: evt.target.value });
    setError('');
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    try {
      setIsLoading(true)
      const user = await usersService.login(credentials);
      setUser(user);
      setIsLoading(false)
    } catch {
      setError('Log In Failed - Try Again');
      setIsLoading(false)
    }
  }

  async function handleGuestLogin(evt) {
    evt.preventDefault()
    try {
      setIsLoading(true)
      const user = await usersService.signUp({
        name: "Guest Account",
        email: `${Date.now() % 1000000}@guest`,
        password: Date.now() % 1000000
      })
      setUser(user);
      setIsLoading(false)
    } catch(error) {
      console.log(error)
      setError('Log In Failed - Try Again');
      setIsLoading(false)
    }
  }

  return (
    <div>
        <h2>Log in</h2>
        <div className="login-ctr">
          <form className="login-form" autoComplete="off" onSubmit={handleSubmit}>
            <label>Email</label>
            <input type="text" name="email" value={credentials.email} onChange={handleChange} />
            <label>Password</label>
            <input type="password" name="password" value={credentials.password} onChange={handleChange} />
            <div></div>
            <button type="submit" disabled={isLoading}>LOG IN</button>
            
            <div></div>
            {/* <label >Want to test the app?</label> */}
            <button className='guest-login flex-ctr-ctr' onClick={handleGuestLogin} disabled={isLoading}><BsFillPersonFill />LOG IN AS GUEST</button>
            <div></div>
            {isLoading ? <Loader /> : null}
          </form>

          {/* <div className="flex-ctr-ctr">
            <h4>Want to test the app?</h4>
            <div className="tooltip-wrap">
              <AiOutlineExclamationCircle className="flex-ctr-ctr tool-tip-icon"/>
              <div className="tooltip-content">
                <p>{
                  `Email: test@test
                  Password: test`
                }</p>
              </div>
            </div>
          </div> */}

        </div>
        <p className="error-message">&nbsp;{error}</p>
    </div>
  );
}