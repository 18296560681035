export default function BaselineMobile({ patientCase, handleDelete }) {
    const ckdKey = {

        "-1": "no known history of CKD",
        0: "no significant history of CKD", //
        2: `Stage 3a CKD due to ${patientCase.ckdEtiology}`,
        3: `Stage 3b CKD due to ${patientCase.ckdEtiology}`,
        4: `Stage 4 CKD due to ${patientCase.ckdEtiology}`,
        5: `Stage 5 CKD due to ${patientCase.ckdEtiology}`,
    }
    return (
        <div className="baseline">
        <button className="delete" onClick={handleDelete}><h3>Remove Case</h3></button>
        <div className="baseline-grid">
            <h4>Case ID:</h4>
            <p>{patientCase._id.slice(18)}</p>
            <h4>Patient Age:</h4>
            <p>{patientCase.age}</p>
            <h4>Creatinine at AKI Onset:</h4>
            <p>{patientCase.creat}</p>
            <h4>Baseline Creatinine:</h4>
            <p>{patientCase.baselineCreat}</p>
            <h4>Underlying CKD:</h4>
            <p>{ckdKey[patientCase.baselineEGFR]}</p>
        </div>
        </div>
    )
}


{/* <td>{patientCase._id}</td>
<td>{patientCase.age}</td>
<td>{patientCase.creat} mg/dL</td>
<td>{patientCase.baselineCreat} mg/dL</td>
<td>{ckdKey[patientCase.baselineEGFR]}</td> */}