import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getCase, deleteCase, getOpenAIImpression, getOpenAIDiagnostics, getOpenAITherapeutics } from "../../utilities/cases-service"
import PrecipitantItemForShow from "./PrecipitantItemForShow";
import BaselineMobile from "./BaselineMobile";
import "./CaseShowPage.css"

import BaselineTable2 from "./BaselineTable2"

import Loader from "../../components/Loader/Loader";


export default function CaseShowPage ( ) {

  const [isLoading, setIsLoading] = useState(true);
  const [patientCase, setCase] = useState(null);
  const [openAIImpression, setOpenAIImpression] = useState(null)
  const [openAIDiagnostics, setOpenAIDiagnostics] = useState(null)
  const [openAITherapeutics, setOpenAITherapeutics] = useState(null)
  const [showOpenAILoader, setShowOpenAILoader] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const navigate = useNavigate()
  
  const { caseid } = useParams();

  async function handleRequest() {
    const caseResponse = await getCase(caseid);
    if (caseResponse?._id) {
      setCase(caseResponse);
      setIsLoading(false);
    } else {
      navigate('/aki')
    }
  }

  async function handleOpenAI() {
    try {
      setButtonDisabled(true)
      setShowOpenAILoader(true)
      const impression = await getOpenAIImpression(caseid)
      const diagnostics = await getOpenAIDiagnostics(caseid)
      const therapeutics = await getOpenAITherapeutics(caseid)
      setOpenAIImpression(impression)
      setOpenAIDiagnostics(diagnostics)
      setOpenAITherapeutics(therapeutics)
      setShowOpenAILoader(false)
      setButtonDisabled(false)
    } catch(err) {
      console.log(err)
      throw Error("something went wrong")
    }
  }

  async function handleDelete(){
    try {
      console.log(caseid)
      const deleteResponse = await deleteCase(caseid)
      if (deleteResponse._id) {
        // console.log('redirecting')
      } else {
        throw Error("something went wrong")
      }
      navigate('/aki')  
    } catch (error) {
      console.log(error)
      // TODO: determine redirect path for error condition
      // set error context in state -> redirect to error
    }
  }

  useEffect(() => {
    handleRequest();
  }, []);


  const precipitantsList = patientCase?.precipitants?.map((p) => <PrecipitantItemForShow precipitant={p} />)

  return isLoading ? (
    <Loader />
  ) : (

      <div className="case-show-ctr">
        <div className="banner">
          <h1>Generated Consult Note</h1>
        </div>

        <section className="flex-ctr-ctr baseline-desktop">
          <div className="baseline">
            <button className="delete" onClick={handleDelete}><h3>Remove Case</h3></button>
            <BaselineTable2 patientCase={patientCase} />
          </div>
        </section>

        <section className="baseline-mobile">
          <BaselineMobile patientCase={patientCase} handleDelete={handleDelete} />
        </section>

        <div className="med-hx-note-style-ctr">
    
  <section className="flex-ctr-ctr-col">
            <h2>Nephrology Consult Note</h2>

    <section className="med-hx-note-style-text">
        <br></br>
          <div className="space-below-header">
            <h4>Summary</h4>
          </div>
          <p>{patientCase.caseSummary.oneLinerVar}</p>
        <br></br>
        <br></br>
          <div className="space-below-header">
            <h4 className="space-below-header">History</h4>
          </div>
          <p>{patientCase.caseSummary.historyBaselineVar}{patientCase.caseSummary.historyPrecipitantsVar}</p>
        <br></br>
        <br></br>
        
          <p>{patientCase.caseSummary.historyPMHVar}</p><br></br>
          <p>{patientCase.caseSummary.caseMedicationsVar}</p><br></br>
          <p>{patientCase.caseSummary.caseAllergiesVar}</p><br></br>
        <br></br>
          <div className="space-below-header">
            <h4 className="space-below-header">Objective</h4>
          </div>
          <p>{patientCase.caseSummary.caseVitalSignsVar} {patientCase.caseSummary.caseUrineOutputVar}</p>
        <br></br>
        <br></br>
          <p>{patientCase.caseSummary.casePhysicalExamVar}</p>
        <br></br>
        <br></br>
          <p>{patientCase.caseSummary.caseLabsVar}</p>
          <p>{patientCase.caseSummary.caseDialyzableToxinsVar}</p>
        <br></br>
        <br></br>
          <p>{patientCase.caseSummary.caseUrineStudiesVar}</p>
        <br></br>
        <br></br>
          <p>{patientCase.caseSummary.caseImagingVar}</p>
        <br></br>
        <br></br>
          <p>{patientCase.caseSummary.caseOtherDataVar}</p>
          <p>{patientCase.caseSummary.caseOtherClinFindingsVar}</p>
        <br></br>
        <br></br>

      </section>
            {/* <p> {patientCase.caseSummary.entireSummary}</p> */}
    </section>

          <hr />
        
          <div className="impression-ctr">
            <section className="flex-start-col">
              <br></br>
              <h3 className="space-below-header">Impression</h3>
              {patientCase.overallImpression}
              <div className="flex-start-col open-ai-div">
                {openAIImpression && <> 
                 <h4 className="flex-ctr-ctr-space-left-indent-open-ai space-below-header">OpenAI Impression:</h4>
                 <p className="open-ai-text"> {openAIImpression}</p> </>}
                 <div className="flex-ctr-ctr">
                  {showOpenAILoader ? <Loader /> : null}
                </div>
              </div>
            </section>

            <hr />
        
            <section className="flex-start-col">
            <br></br>
              <h4 className="space-below-header">Diagnostic Recommendations</h4>
              {patientCase.diagnosticTestsForPlan.finalized?.map((val) => (
                <li>{val}</li>
              ))}

              <div className="flex-start-col open-ai-div">
                {openAIDiagnostics && <>
                <h4 className="flex-ctr-ctr-space-left-indent-open-ai space-below-header">OpenAI Diagnostics:</h4>
                <p className="open-ai-text">{openAIDiagnostics} </p></>}
                <div className="flex-ctr-ctr">
                  {showOpenAILoader ? <Loader /> : null}
                </div>
              </div>
            </section>

            <hr />

            <section className="flex-start-col">
            <br></br>
              <h4 className="space-below-header">Therapeutic Recommendations</h4>
              {patientCase.therapeuticsForPlan.finalized?.map((val) => (
                <li>{val}</li>
              ))}
              <li>avoid nephrotoxic exposures as able and renally dose all pertinent medications</li>

              <div className="flex-start-col open-ai-div">
                {openAITherapeutics && <><h4 className="flex-ctr-ctr-space-left-indent-open-ai space-below-header">OpenAI Therapeutics:</h4> 
                <p className="open-ai-text">{openAITherapeutics}</p></>}
                <div className="flex-ctr-ctr">
                  {showOpenAILoader ? <Loader /> : null}
                </div>
              </div>
            </section>

            <hr />

          </div>
          <button className="openAI-button" onClick={handleOpenAI} disabled={buttonDisabled}><h3>Additional Insight from OpenAI</h3></button>

        </div>
      </div>
      );
}
