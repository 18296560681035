import { getCases } from "../../utilities/cases-service";
import Loader from "../../components/Loader/Loader";
import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
// import CasesList from "./CasesList";
import CaseItem from "./CaseItem";
import "./IndexPage.css"


export default function IndexPage () {
    const [cases, setCases] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    async function handleRequest() {
        const response = await getCases();
        if(typeof response === "object") {
            setCases(response)
            setIsLoading(false)
        } else {
            console.log("error: ", response)
        }
    }
    useEffect(() => {
        handleRequest();
      }, [])

      const patientCasesList = cases?.map((patientCase, idx) => <CaseItem patientCase={patientCase} idx={idx} handleRequest={handleRequest}/>)
    
      return isLoading ? (
        <Loader />
      ) : (
        <div className="cases-index-ctr">
            <div className="banner">
                <h1 className="flex-ctr-ctr">My AKI Consult Cases</h1>
            </div>
            {!cases.length ? 
            <>
                <div className="new-case-div">
                <Link className="link" to="/aki/new-case">
                    <button><h4>Create New Case</h4></button>
                </Link>
                </div>
                <div className="new-case-div-text">
                <h3>No existing cases</h3> 
                </div>
            </>
            : 
            <>
              <div className="new-case-div">
                <Link className="link" to="/aki/new-case">
                    <button ><h4>Create New Case</h4></button>
                </Link>
              </div>
                <div className="flex-ctr-ctr">
                    <table className="index-table">
                        <thead>
                            <tr>
                                <th>Case ID Link</th>
                                <th>Date Created</th>
                                <th className="delete-cell"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {patientCasesList}
                        </tbody>
                    </table>
                </div>
                <br></br>
            </>
        }
        </div>
  );
}

