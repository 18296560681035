import {Routes, Route} from 'react-router-dom'

import IndexPage from '../../pages/IndexPage/IndexPage'
import UserShowPage from '../../pages/UserShowPage/UserShowPage'
import ErrorPage from '../../pages/ErrorPage/ErrorPage'
import AuthPage from '../../pages/AuthPage/AuthPage'
// import NavBar from '../NavBar/NavBar'
import NewCasePage from '../../pages/NewCasePage/NewCasePage'
import NewCasePage2 from '../../pages/NewCasePage/NewCasePage2'
import CaseShowPage from '../../pages/CaseShowPage/CaseShowPage'
import AboutPage from '../../pages/AboutPage/AboutPage'
import EditUserPage from '../../pages/EditUserPage/EditUserPage'
// import Footer from '../Footer/Footer'
import "./Main.css"


export default function Main({user, setUser}){


    return(
        <main className="main">
        { user ? 
        <>
          <Routes>
            <Route path="/aki" element={<IndexPage />} /> 
            <Route path="/aki/about" element={<AboutPage />} />
            <Route path="/aki/user" element={<UserShowPage user={user} setUser={setUser} />} />
            <Route path="/aki/user/edit" element={<EditUserPage user={user} setUser={setUser} />} />
            <Route path="/aki/new-case" element={<NewCasePage />} />   
            <Route path="/aki/new-case/:caseid/2" element={<NewCasePage2 />} />   
            <Route path="/aki/cases/:caseid" element={<CaseShowPage />} />   
            <Route path="/*" element={<ErrorPage />} />   
          </Routes>
        </>
          :
    
          <AuthPage setUser={setUser} />
      }
        </main>
    )
}

