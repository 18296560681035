import "./AboutPage.css"
import { FaUserDoctor, FaPeopleGroup } from 'react-icons/fa6';
import { TbAugmentedReality2 } from 'react-icons/tb';
import { BsLinkedin, BsGithub } from 'react-icons/bs';


export default function AboutPage() {

    return (
        <div className="about-ctr">
            <div className="banner">
                <div className="flex-ctr-ctr-col">
                {/* <h1 className="renal-consults-text">RenalConsults.com</h1> */}
                <h1>About</h1>
                <br></br>
                <h4 className="renal-consults-text">THE RENAL CONSULT NOTE GENERATOR</h4>
                </div>
            </div>

            <section className="about-section flex-ctr-ctr-col">
            <br></br>
            <h2>A <span>Foley</span> Capable App</h2>
            <div className="flex-ctr-ctr">
                <div className="about-icon-div">
                    <FaUserDoctor className="about-icon" />
                </div>
                <div className="about-section-text flex-ctr-ctr-col">
                    <p>Welcome to <p className="renal-consults-text">RenalConsults.com</p>, your indispensable companion for generating nephrology consult notes. 
    
                    Our application has been meticulously designed to empower you with a cutting-edge tool for effortlessly generating comprehensive consult summaries, diagnostic impressions, and therapeutic recommendations. 
                    <br></br>
                    <br></br>
                    Start by creating a new case and filling in all pertinent patient data. Then, watch as our intelligent system compiles your findings into a succinct and informative clinical consult note.
                    <br></br>
                    <br></br>
                    Note that version 1.0, released Sept. 2023, focuses on consult notes specifically for AKI.</p>
                    </div>
                    </div>
            </section>

            <section className="about-section flex-ctr-ctr-col">
            <br></br>
            <h2>AI Integration</h2>
            <div className="flex-ctr-ctr">
                <div className="about-icon-div">
                    <TbAugmentedReality2 className="about-icon" />
                </div>
                <div className="about-section-text flex-ctr-ctr-col">
                    
                    <p>What sets us apart is our integration with OpenAI, which provides valuable insights into potentially missed differential diagnoses and concerns, enhancing your diagnostic capabilities. Join us on this journey to elevate your practice and provide the best care possible. Welcome to a new era of healthcare.
                    <br></br>
                    <br></br>
                    Please be sure to comply with your local patient privacy policies and laws regarding submission of any consult case data to OpenAI through our application.
                    </p>
                </div>
                </div>
            </section>

            <section className="about-section flex-ctr-ctr-col">
            <br></br>
            <h2>Our Mission</h2>
            <div className="flex-ctr-ctr">
                <div className="about-icon-div">
                    <FaPeopleGroup className="about-icon" />
                </div>
                <div className="about-section-text flex-ctr-ctr-col">
                    
                    <p>We created the this nephrology consult application because we believe that healthcare providers deserve a powerful tool that simplifies and enhances the process of generating consult summaries and diagnostic impressions. Our mission is to streamline the often complex and time-consuming task of compiling patient data into meaningful clinical notes. By harnessing the potential of technology and integrating OpenAI, we aim to empower healthcare professionals with the ability to provide more accurate, efficient, and comprehensive care to their patients. We envision a future where healthcare providers can focus more on patient interaction and treatment decisions, while our application handles the intricacies of data analysis and note generation. Our "why" is rooted in the commitment to improve healthcare delivery and make a tangible difference in the lives of both providers and patients.</p>
                </div>
              </div>
            </section>

            <section className="about-section flex-ctr-ctr-col">
                <h2 className="about-section-text-founding-devs">Founding Developers</h2>
                <div className="our-team flex-ctr-ctr-space-even">
                    <div className="flex-ctr-ctr profile">
                        <img className="headshot" src="https://i.imgur.com/x4lnmgB.jpg" alt="" />
                        <div className="about-section-text flex-ctr-ctr-col">
                            <h2>Robert Hayden, MD</h2>
                            {/* <p>placeholder</p> */}
                            <div className="contact-links flex-ctr-ctr">
                                <a href="https://www.linkedin.com/in/robert-m-hayden/" target="_blank">
                                    <BsLinkedin className="social-media-icon flex-ctr-ctr"/>
                                </a>
                                <a href="https://github.com/rmhayden" target="_blank">
                                    <BsGithub className="social-media-icon flex-ctr-ctr"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="profile flex-ctr-ctr">
                        <img className="headshot" src="https://i.imgur.com/WTYGJfC.jpg" alt="" />
                        <div className="about-section-text flex-ctr-ctr-col">
                            <h2>William Hu, RN</h2>
                            {/* <p>placeholder</p> */}
                            <div className="contact-links flex-ctr-ctr">
                                <a href="https://www.linkedin.com/in/william-wl-hu/" target="_blank">
                                    <BsLinkedin className="social-media-icon flex-ctr-ctr"/>
                                </a>
                                <a href="https://github.com/william-hu-codes" target="_blank">
                                    <BsGithub className="social-media-icon flex-ctr-ctr"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}