export default function BaselineTableMobile({ patientCase }) {
    const ckdKey = {

        "-1": "no known history of CKD",
        0: "no significant history of CKD", //
        2: `Stage 3a CKD due to ${patientCase.ckdEtiology}`,
        3: `Stage 3b CKD due to ${patientCase.ckdEtiology}`,
        4: `Stage 4 CKD due to ${patientCase.ckdEtiology}`,
        5: `Stage 5 CKD due to ${patientCase.ckdEtiology}`,
    }

    return (

        <div className="baseline-grid">
            <h4>Patient Age:</h4>
            <p>{patientCase.age}</p>
            <h4>Creatinine at AKI Onset:</h4>
            <p>{patientCase.creat}</p>
            <h4>Baseline Creatinine:</h4>
            <p>{patientCase.baselineCreat}</p>
            <h4>Underlying CKD:</h4>
            <p>{ckdKey[patientCase.baselineEGFR]}</p>
            <h4>Pertinent Medical History:</h4>
            {(patientCase.pertinentMedicalHistory) ?
                <>
                {patientCase.pertinentMedicalHistory}
                </>
                :
                <p>none pertinent</p> }
        </div>


        // <table className="baseline-table">
        //     <thead>
        //         <tr>
        //             <th>Patient Age</th>
        //             <th>Creatinine at AKI Onset</th>
        //             <th>Baseline Creatinine</th>
        //             <th>Underlying CKD</th>
        //             <th>Pertinent Medical History</th>
        //         </tr>
        //     </thead>
        //     <tbody>
        //         <tr>
        //             <td>{patientCase.age}</td>
        //             <td>{patientCase.creat} mg/dL</td>
        //             <td>{patientCase.baselineCreat} mg/dL</td>
        //             <td>{ckdKey[patientCase.baselineEGFR]}</td>
        //             <td> <> {(patientCase.pertinentMedicalHistory) ?
        //             <>
        //             {patientCase.pertinentMedicalHistory}
        //             </>
        //             :
        //             <p>none pertinent</p>
        //                 }  
        //             </>
                         
        //             </td>
        //         </tr>
        //     </tbody>
        // </table>
    )
}