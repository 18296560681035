import PrecipitantItem from "./PrecipitantItem";

export default function PrecipitantsTable({ patientCase, caseid, handleRequest }) {
    const precipitantsList = patientCase?.precipitants?.map((p) => <PrecipitantItem handleRequest={handleRequest} precipitant={p} caseid={caseid} />)

    return (
        <>
        <table className="precipitants-table">
            <thead>
                <tr>
                    <th>Type of Precipitant</th>
                    <th>Specific Etiology</th>
                    <th>Weight of Significance</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {precipitantsList}
            </tbody>
        </table>
        {!precipitantsList.length && "No precipitants yet"}
        </>
    )
}