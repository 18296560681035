import { Link } from "react-router-dom";
import { deleteCase } from "../../utilities/cases-service"


export default function CaseItem({patientCase, idx, handleRequest}) {

    async function handleDelete(){
        try {
    
        //   console.log(patientCase._id)
          const deleteResponse = await deleteCase(patientCase._id)
          if (deleteResponse._id) {
            // console.log('case deleted')
            handleRequest()
          } else {
            throw Error("something went wrong")
          }      
        } catch (error) {
          console.log(error)
          // log the error
          // navigate() back to show page
    
          // TODO: determine redirect path for error condition
          // set error context in state -> redirect to error
        }
      }

    return (
        <tr key={idx}>
            <td>
                <Link to={`/aki/cases/${patientCase._id}`}>
                    <button><strong>Link to Case ID:</strong> {patientCase._id.slice(18)}</button>
                </Link>
            </td>
            <td>
                <h5>{new Date(patientCase.createdAt).toDateString()} at {new Date(patientCase.createdAt).toLocaleTimeString()}</h5>
            </td>
            <td className="delete-cell">
                <button className="delete" onClick={handleDelete}><h4>Remove</h4></button>
            </td>
        </tr>
    )
}
