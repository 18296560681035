
import { useState, useEffect } from "react";
import { createCase, getMostRecentCase } from "../../utilities/cases-service"
import { useNavigate } from "react-router-dom";
import "./NewCasePage.css"


export default function NewCaseForm() {

  const [form1, setForm1] = useState({});

  const navigate = useNavigate()

  async function handleSubmit(e){
    e.preventDefault()
    await createCase(form1)
    const newCase = await getMostRecentCase()
    navigate(`/aki/new-case/${newCase._id}/2`)


  }

  function handleChange(e){
    const updatedData = { ...form1, [e.target.name]: e.target.value }
    setForm1(updatedData)
  }
  
  return (
    <>
    <div className="banner">
      <h1>New Case</h1>
    </div>
    <section className="case-form-1">
      <form onSubmit={handleSubmit}>
       
        <h2>Baseline Characteristics and AKI Severity</h2>
        <hr />

       <label className="flex-ctr-ctr-space-left" htmlFor="age">
          <h4>Age</h4>
          <input className="selector" 
            type="number"
            name="age"
            id="age"
            min={0}
            placeholder="age"
            value={form1.age}
            onChange={handleChange}
            required
          />
        </label>

        <label className="flex-ctr-ctr-space-left" htmlFor="baselineCreat">
        <h4>Baseline Creatinine (mg/dL)</h4>
          <input className="selector" 
            type="number"
            min={0.3}
            max={20}
            step="any"
            name="baselineCreat"
            id="baselineCreat"
            placeholder="add baseline creatinine value"
            value={form1.baselineCreat}
            onChange={handleChange}
            required
          />
        </label>


        <label className="flex-ctr-ctr-space-left" htmlFor="baselineEGFR">
          <h4>Corresponding Baseline eGFR</h4>
          <select className="selector"  name="baselineEGFR" id="baselineEGFR" value={form1.baselineEGFR} onChange={handleChange} required>

          <option selected disabled>Select Baseline eGFR</option>
                      <option value={-1}>unknown</option>
                      <option value={0}>No significant CKD: eGFR of 60 mL/min/1.73m2 or greater</option>
                      <option value={2}>CKD Stage 3a: eGFR range 45-59 mL/min/1.73m2</option>
                      <option value={3}>CKD Stage 3b: eGFR range 30-44 mL/min/1.73m2</option>
                      <option value={4}>CKD Stage 4: eGFR range 15-29 mL/min/1.73m2</option>
                      <option value={5}>CKD Stage 5: eGFR less than 15 mL/min/1.73m2</option>
         </select>
        </label>


      {(form1.baselineEGFR >= 2) ?

      <>

      <label className="flex-ctr-ctr-space-left-indent" htmlFor="ckdEtiology">
          <h4>CKD is primarily due to ... </h4>
          <input className="selector" 
            type="string"
            name="ckdEtiology"
            id="ckdEtiology"
            placeholder="CKD due to ..."
            defaultValue="unknown etiology"
            value={form1.ckdEtiology}
            onChange={handleChange}
          />
        </label>

      </>
      
        :

        null

      }


        <label className="flex-ctr-ctr-space-left" htmlFor="creat">
          <h4>Peak AKI Creatinine (mg/dL)</h4>
          <input className="selector" 
            type="number"
            min={0.3}
            max={20}
            step="any"
            name="creat"
            id="creat"
            placeholder="add creatinine at AKI onset"
            value={form1.creat}
            onChange={handleChange}
            required
          />
        </label>



        <label className="flex-ctr-ctr-space-left" htmlFor="pertinentMedicalHistory">
        <h4>Pertinent Medical History</h4>
          <input className="selector" 
            type="string"
            name="pertinentMedicalHistory"
            id="pertinentMedicalHistory"
            placeholder="list pertinent PMH, separate each with comma"
            value={form1.pertinentMedicalHistory}
            onChange={handleChange}
          />
        </label>

        <button type="submit"><h3>Create Case</h3></button>
      </form>
    </section>
    </>
  );
}
