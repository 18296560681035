import "../NewCasePage/NewCasePage.css"


export default function BaselineTable2({ patientCase }) {


    const ckdKey = {

        "-1": "no known history of CKD",
        0: "no significant history of CKD", //
        2: `Stage 3a CKD due to ${patientCase.ckdEtiology}`,
        3: `Stage 3b CKD due to ${patientCase.ckdEtiology}`,
        4: `Stage 4 CKD due to ${patientCase.ckdEtiology}`,
        5: `Stage 5 CKD due to ${patientCase.ckdEtiology}`,
    }

    return (
        <table className="baseline-table">
            <thead>
                <tr>
                    <th>Case ID</th>
                    <th>Patient Age</th>
                    <th>Creatinine at AKI Onset</th>
                    <th>Baseline Creatinine</th>
                    <th>Underlying CKD</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{patientCase._id.slice(18)}</td>
                    <td>{patientCase.age}</td>
                    <td>{patientCase.creat} mg/dL</td>
                    <td>{patientCase.baselineCreat} mg/dL</td>
                    <td>{ckdKey[patientCase.baselineEGFR]}</td>
                </tr>
            </tbody>
        </table>
    )

}

