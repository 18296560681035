import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getCase, createPrecipitant, updateCase } from "../../utilities/cases-service"
import initForm3 from "./NewCasePage2InitState"
import BaselineTable from "./BaselineTable";
import PrecipitantsTable from "./PrecipitantsTable";
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import BaselineTableMobile from "./BaselineTableMobile";

import Loader from "../../components/Loader/Loader";

export default function NewCasePage2() {
    const [form2, setForm2] = useState({weight: 2})

    const [form3, setForm3] = useState(initForm3)

    const [isLoading, setIsLoading] = useState(true);
    const [patientCase, setCase] = useState(null);

    const navigate = useNavigate()
  
    const { caseid } = useParams(); 

  async function handleRequest() {
    const caseResponse = await getCase(caseid);
    if (caseResponse?._id) {
      setCase(caseResponse);
      setIsLoading(false);
    } else {
      navigate('/aki')
    }
  }

  useEffect(() => {
    handleRequest();
  }, []);

    function handleChange2(e){
        const updatedData = { ...form2, [e.target.name]: e.target.value }
        setForm2(updatedData)
    }

    async function handleSubmit(evt) {
        evt.preventDefault()
        await createPrecipitant(caseid, form2)
        setForm2({weight: 2,type: "other", specific:""})
        handleRequest()
    }


  
  function handleChange3(e) {
    const newDataForm3 = { ...form3, [e.target.name]: e.target.value }
    setForm3(newDataForm3)
  }

//   console.log(form3)

 async function handleSubmit3(e){
    e.preventDefault()
    await updateCase(caseid, form3)
    navigate(`/aki/cases/${caseid}`)
  }

    return isLoading ? (
    <Loader />
    ) : (
    <div className="case-form-page-2">
        <div className="banner">
            <h1>New Case</h1>
        </div>
    <br></br>
    <div className="baseline">
        <h2>Baseline Characteristics and AKI Severity</h2>
        <div className="baseline-desktop">
            <BaselineTable patientCase={patientCase} />
        </div>
        <div className="baseline-mobile">
            <BaselineTableMobile patientCase={patientCase} />
        </div>
    </div>

    <form className="form-2" onSubmit={handleSubmit}>
    <div className="precipitants">
        <h2>Possible Precipitants of AKI</h2>

        <> {patientCase.precipitants.length ?

        <>
        <PrecipitantsTable patientCase={patientCase} caseid={caseid} handleRequest={handleRequest} />
        </>
        :
        null
        }
        </>
    </div>
    
        <div className="flex-ctr-ctr-col">
            <br></br>
            <h3>Add precipitant(s)</h3>
            <br></br>
            <div className="flex-ctr-ctr-space-even precipitants-form">
                <label className="flex-ctr-ctr-col" htmlFor="type">
                    <h4 className="space-below-header">Precipitant type:</h4>
                    <select className="selector" name="type" id="type" value={form2.type} onChange={handleChange2} required>

                        <option selected disabled>Select type</option>
                        <option value="hypoperfusion">hypoperfusion</option>
                        <option value="inflammatory">inflammatory</option>
                        <option value="nephrotoxic">nephrotoxic</option>
                        <option value="anatomical">anatomical</option>
                        <option value="other">other</option>
                    </select>
                </label>
                <label className="flex-ctr-ctr-col" htmlFor="specific"> <h4 className="space-below-header">Specific etiology:&#160;&#160;&#160;&#160;</h4>
                    <input className="selector" type="text" name="specific" value={form2.specific}  onChange={handleChange2} id="specific" required/>
                </label>
                <label className="flex-ctr-ctr-col" htmlFor="weight">
                    <div className="flex-ctr-ctr">
                    <div className="tooltip-wrap" id="weight-tooltip-wrap">
                            <AiOutlineExclamationCircle className="flex-ctr-ctr tool-tip-icon"/>
                            <div className="tooltip-content">
                                <p>{`Based on your clinical judgement, weigh the likelihood that each of the precipitants may have caused renal injury`}</p>
                            </div>
                        </div>
                        <h4 className="space-below-header">Weight [ {form2.weight} ] </h4> 
                        
                    </div>
                    <input type="range" name="weight" min={1} max={3} value={form2.weight} className="slider" id="weight" onChange={handleChange2} />
                </label>
            </div>
            <button type="submit"> <h3>Add Precipitant</h3> </button>
            <br></br>
        </div>
    </form>
    

    <form onSubmit={handleSubmit3} className="form3">

    <div className="case-form-3">
    <div className="additional-diagnostics-and-clinical-data">
        <h2>Additional Diagnostics and Clinical Data</h2>

        <label className="flex-ctr-ctr-space-left" htmlFor="imaging">
            <h4>Kidney Imaging Since AKI Onset:</h4>
            <select className="selector-form-3" name="imaging" id="imaging" value={form3.imaging} onChange={handleChange3}>
                <option value={false}>no preceding renal imaging available</option>
                <option value={true}>imaging available</option>
            </select>
        </label>
        
        {(form3.imaging === "true") ?
        <>
            <div className="flex-ctr-ctr-space-left-indent">
                <h4>Hydronephrosis on imaging?:</h4>
                <label htmlFor="hydronephrosis">
                    <select className="selector-form-3" name="hydronephrosis" id="hydronephrosis" value={form3.hydronephrosis} onChange={handleChange3}>
                        <option value={0}>no evidence of hydronephrosis</option>
                        <option value={1}>unilateral hydronephrosis present</option>
                        <option value={2}>mild/moderate bilateral hydronephrosis present</option>
                        <option value={3}>severe bilateral hydronephrosis present</option>
                    </select>
                </label>
            </div>
        </>
        :
        null
        }


        <label className="flex-ctr-ctr-space-left" htmlFor="proteinuria">
            <h4>Proteinuria:</h4>
            <select className="selector-form-3" name="proteinuria" id="proteinuria" value={form3.proteinuria} onChange={handleChange3}>
                <option value={-1}>not yet checked</option>
                <option value={0}>no proteinuria</option>
                <option value={1}>1+ proteinuria</option>
                <option value={2}>2+ proteinuria</option>
                <option value={3}>3+ proteinuria</option>
            </select>
        </label>



        {(form3.proteinuria > 0) ?

        <>

        <label className="flex-ctr-ctr-space-left-indent" htmlFor="priorProteinuria">
            <h4>Prior Proteinuria:</h4>
            <select className="selector-form-3" name="priorProteinuria" id="priorProteinuria" value={form3.priorProteinuria} onChange={handleChange3}>
                <option value={false}>no prior proteinuria</option>
                <option value={true}>history of prior proteinuria</option>
            </select>
        </label>

        </>

        :

        null
   
        }

        <label className="flex-ctr-ctr-space-left" htmlFor="hematuria">
            <h4>Hematuria:</h4>
            <select className="selector-form-3" name="hematuria" id="hematuria" value={form3.hematuria} onChange={handleChange3}>
                <option value={-1}>not yet checked</option>
                <option value={0}>no hematuria</option>
                <option value={1}>1+ hematuria</option>
                <option value={2}>2+ hematuria</option>
                <option value={3}>3+ hematuria</option>
            </select>
        </label>
    
            {(form3.hematuria > 0) ?

            <>

        <label className="flex-ctr-ctr-space-left-indent" htmlFor="priorHematuria">
            <h4>Prior Hematuria:</h4>
            <select className="selector-form-3" name="priorHematuria" id="priorHematuria" value={form3.priorHematuria} onChange={handleChange3}>
                <option value={false}>no prior hematuria</option>
                <option value={true}>history of prior hematuria</option>
            </select>
        </label>

        </>

        :

        null

        }

        <label className="flex-ctr-ctr-space-left" htmlFor="casts">
            <h4>Urine Sediment Microscopy:</h4>
            <select className="selector-form-3" name="casts" id="casts" value={form3.casts} onChange={handleChange3}>
                <option value={-1}>has not been evaluated yet</option>
                <option value={0}>no casts or other abnormalities seen on sediment</option>
                <option value={1}>few casts and/or subtle other abnormalities are present</option>
                <option value={2}>many casts and/or striking other abnormalities are present</option>
            </select>
        </label>

            
        {(form3.casts >= 1) ?

            <>

        <label className="flex-ctr-ctr-space-left-indent" htmlFor="castsHyaline">
            <h4>Hyaline Casts:</h4>
            <select className="selector-form-3" name="castsHyaline" id="castsHyaline" value={form3.castsHyaline} onChange={handleChange3}>
                    <option value={false}>no hyaline casts</option>
                    <option value={true}>hyaline casts observed</option>
            </select>
        </label>

        <label className="flex-ctr-ctr-space-left-indent" htmlFor="castsMuddyBrown">
            <h4>Muddy Brown Casts:</h4>
            <select className="selector-form-3" name="castsMuddyBrown" id="castsMuddyBrown" value={form3.castsMuddyBrown} onChange={handleChange3}>
                <option value={false}>no muddy brown casts</option>
                <option value={true}>muddy brown casts observed</option>
            </select>
        </label>

        <label className="flex-ctr-ctr-space-left-indent" htmlFor="castsWhiteBloodCell">
            <h4>White Blood Cell Casts:</h4>
            <select className="selector-form-3" name="castsWhiteBloodCell" id="castsWhiteBloodCell" value={form3.castsWhiteBloodCell} onChange={handleChange3}>
                <option value={false}>no white blood cell casts</option>
                <option value={true}>white blood cell casts observed</option>
            </select>
        </label>


        <label className="flex-ctr-ctr-space-left-indent" htmlFor="castsRedBloodCell">
            <h4>Acanthocytes and/or Red Blood Cell Casts:</h4>
            <select className="selector-form-3" name="castsRedBloodCell" id="castsRedBloodCell" value={form3.castsRedBloodCell} onChange={handleChange3}>
                <option value={false}>no acantyocytes and/or red blood cell casts</option>
                <option value={true}>acantyocytes and/or red blood cell casts are present</option>
            </select>
        </label>

        </>

        :

        null

        }

        <label className="flex-ctr-ctr-space-left" htmlFor="thrombocytopenia">
            <h4>Thrombocytopenia:</h4>
            <select className="selector-form-3" name="thrombocytopenia" id="thrombocytopenia" value={form3.thrombocytopenia} onChange={handleChange3}>
                <option value={-1}>not yet checked</option>
                <option value={0}>no thrombocytopenia</option>
                <option value={1}>mild thrombocytopenia present</option>
                <option value={2}>moderate/severe thrombocytopenia present</option>
                <option value={91}>thrombocytopenia but clear alternative etiology likely unrelated to AKI</option>
            </select>
        </label>

        <label className="flex-ctr-ctr-space-left" htmlFor="hemolysis">
            <h4>Hemolysis:</h4>
            <select className="selector-form-3" name="hemolysis" id="hemolysis" value={form3.hemolysis} onChange={handleChange3}>
                <option value={-1}>hemolysis labs not yet checked</option>
                <option value={0}>no lab evidence of hemolysis</option>
                <option value={1}>mild hemolysis present</option>
                <option value={2}>moderate/severe hemolysis present</option>
                <option value={91}>hemolysis but clear alternative etiology likely unrelated to AKI</option>
            </select>
        </label>
 
        <label className="flex-ctr-ctr-space-left" htmlFor="serologies"> 
            <h4>Serologies:</h4>
            {/* <h6>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</h6> */}
            <div className="tooltip-wrap">
                <AiOutlineExclamationCircle className="flex-ctr-ctr tool-tip-icon"/>
                <div className="tooltip-content">
                    <p>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</p>
                </div>
            </div>
            <input className="selector-form-3" type="text" name="serologies" value={form3.serologies}  onChange={handleChange3} id="serologies"/>
            
        </label> 

        <label className="flex-ctr-ctr-space-left" htmlFor="clinOtherData"> 
        <h4>Any Other Data:</h4>
        {/* <h6>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</h6> */}
            <div className="tooltip-wrap">
                <AiOutlineExclamationCircle className="flex-ctr-ctr tool-tip-icon"/>
                <div className="tooltip-content">
                    <p>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</p>
                </div>
            </div>
        <textArea className="selector-form-3-text-area" type="text" name="clinOtherData" value={form3.clinOtherData}  onChange={handleChange3} id="clinOtherData"/>
    
        </label>

    </div>   

<div className="clinical-findings-with-therapeutic-implications">
<br></br>
    <h2>Clinical Findings with Therapeutic Implications</h2>

    <label className="flex-ctr-ctr-space-left" htmlFor="clinUremia">
        <h4>Uremic Signs/Symptoms:</h4>
        <select className="selector-form-3" name="clinUremia" id="clinUremia" value={form3.clinUremia} onChange={handleChange3}>
            <option value={0}>not clearly present</option>
            <option value={1}>mild findings concerning for uremia present</option>
            <option value={3}>definitive findings of uremia present</option>
        </select>
    </label>

    <label className="flex-ctr-ctr-space-left" htmlFor="clinEdema">
        <h4>Peripheral Edema on Exam:</h4>
        <select className="selector-form-3" name="clinEdema" id="clinEdema" value={form3.clinEdema} onChange={handleChange3}>
            <option value={0}>none present</option>
            <option value={1}>1+ edema present</option>
            <option value={2}>2+ edema present</option>
            <option value={3}>3+ edema present</option>
        </select>
    </label>

    <label className="flex-ctr-ctr-space-left" htmlFor="clinPulmEdema">
        <h4>Pulmonary Edema on Exam and/or Imaging:</h4>
        <select className="selector-form-3" name="clinPulmEdema" id="clinPulmEdema" value={form3.clinPulmEdema} onChange={handleChange3}>
            <option value={0}>none present</option>
            <option value={1}>mild pulmonary edema present</option>
            <option value={2}>moderate pulmonary edema present</option>
            <option value={3}>severe pulmonary edema present</option>
        </select>
    </label>

    <label className="flex-ctr-ctr-space-left" htmlFor="clinLowECBV">
        <h4>Evidence of Low Effective Arterial Blood Volume (EABV):</h4>
        <select className="selector-form-3" name="clinLowECBV" id="clinLowECBV" value={form3.clinLowECBV} onChange={handleChange3}>
            <option value={0}>no clear evidence of low EABV</option>
            <option value={2}>possibly low EABV</option>
            <option value={3}>likely low EABV</option>
        </select>
    </label>

    <label className="flex-ctr-ctr-space-left" htmlFor="clinVolumeChallengeGiven">
        <h4>Volume Challenge Given?</h4>
        <select className="selector-form-3" name="clinVolumeChallengeGiven" id="clinVolumeChallengeGiven" value={form3.clinVolumeChallengeGiven} onChange={handleChange3}>
            <option value={0}>no volume challenge given</option>
            <option value={1}>volume challenge given but possibly inadequate</option>
            <option value={2}>adequate volume challenge has been given</option>
        </select>
    </label>

    {/* {(form3.clinVolumeChallengeGiven > 0) ?

    <>

    <label className="flex-ctr-ctr-space-left-indent" htmlFor="clinImprovedCreatWithVolume">
        <h4>Improvement in serum creatinine after volume challenge?</h4>
        <select name="clinImprovedCreatWithVolume" id="clinImprovedCreatWithVolume" value={form3.clinImprovedCreatWithVolume} onChange={handleChange3}>
            <option value={-1}>unable to determine at this time</option>
            <option value={0}>no improvement in serum creatinine</option>
            <option value={12}>improvement in serum creatinine</option>
        </select>
    </label>

    </>

    :

    null

    } */}
        
    <label className="flex-ctr-ctr-space-left" htmlFor="clinUrineOutput">
        <h4>Urine Output:</h4>
        <select className="selector-form-3" name="clinUrineOutput" id="clinUrineOutput" value={form3.clinUrineOutput} onChange={handleChange3}>
            <option value={-1}>urine output not known</option>
            <option value={0}>non-oliguric (at least 500cc/day)</option>
            <option value={1}>oliguric (less than 500cc/day)</option>
            <option value={3}>anuric</option>
        </select>
    </label>

    {(form3.clinUrineOutput >= 1) ?

    <>

    <label className="flex-ctr-ctr-space-left-indent" htmlFor="clinUrineOutputRefractory">
        <h4>Oligoanuria Refractory to Diuretics?</h4>
        <select className="selector-form-3" name="clinUrineOutputRefractory" id="clinUrineOutputRefractory" value={form3.clinUrineOutputRefractory} onChange={handleChange3}>
            <option value={-1}>not applicable</option>
            <option value={0}>diuretics not given</option>
            <option value={1}>diuretics given but dose not maximized</option>
            <option value={3}>diuretics given at maximal dose</option>
        </select>
    </label>

    </>

    :

    null

    }

    <label className="flex-ctr-ctr-space-left" htmlFor="clinHyperkalemia">
        <h4>Hyperkalemia:</h4>
        <select className="selector-form-3" name="clinHyperkalemia" id="clinHyperkalemia" value={form3.clinHyperkalemia} onChange={handleChange3}>
            <option value={false}>no hyperkalemia</option>
            <option value={true}>hyperkalemia is present</option>
        </select>
    </label>
    
    { (form3.clinHyperkalemia === "true") ?

    <>
        
    <label className="flex-ctr-ctr-space-left-indent" htmlFor="clinPotassium"> 
        <h4>Serum Potassium Value (mEq/L):</h4>
        <input className="selector-form-3" type="number" name="clinPotassium" value={form3.clinPotassium} step={"any"} min={5.1} max={8.5} onChange={handleChange3} id="clinPotassium" required/>
    </label>

    <label className="flex-ctr-ctr-space-left-indent" htmlFor="clinHyperkalemiaOtherCauses"> 
        <h4>Other possible causes of hyperkalemia aside from AKI:</h4>
        <input className="selector-form-3" type="text" name="clinHyperkalemiaOtherCauses" value={form3.clinHyperkalemiaOtherCauses}  onChange={handleChange3} id="clinHyperkalemiaOtherCauses"/>
    </label>

    </>

    :

    null

    }

    <label className="flex-ctr-ctr-space-left" htmlFor="clinMetabolicAcidosis">
        <h4>Acidemia Caused by Primary Metabolic Acidosis:</h4>
        <select className="selector-form-3" name="clinMetabolicAcidosis" id="clinMetabolicAcidosis" value={form3.clinMetabolicAcidosis} onChange={handleChange3}>
            <option value={false}>no metabolic acidosis</option>
            <option value={true}>metabolic acidosis is present</option>
        </select>
    </label>

    { (form3.clinMetabolicAcidosis === "true") ?

    <>


<label className="flex-ctr-ctr-space-left-indent" htmlFor="clinBicarb"> 
        <h4>Bicarb Value (mEq/L):</h4>
        <input className="selector-form-3" type="number" name="clinBicarb" value={form3.clinBicarb} step={"any"} min={5} max={23} onChange={handleChange3} id="clinBicarb" required/>
    </label>


    <label className="flex-ctr-ctr-space-left-indent" htmlFor="clinPH"> 
        <h4>pH Value:</h4>
        <input className="selector-form-3" type="number" name="clinPH" value={form3.clinPH} step={"any"} min={6.8} max={7.35} onChange={handleChange3} id="clinPH" required/>
    </label>

    </>

    :

    null

    }


    <label className="flex-ctr-ctr-space-left" htmlFor="clinDialyzableToxinPresent">
        <h4>Other Dialyzable Toxin / Ingestion:</h4>
        <select className="selector-form-3" name="clinDialyzableToxinPresent" id="clinDialyzableToxinPresent" value={form3.clinDialyzableToxinPresent} onChange={handleChange3}>
            <option value={false}>no dialyzable toxin / ingestion</option>
            <option value={true}>dialyzable toxin / ingestion present</option>
        </select>
    </label>


    { (form3.clinDialyzableToxinPresent === "true") ?

    <>

    <label className="flex-ctr-ctr-space-left-indent" htmlFor="clinDialyzableToxins"> 
    <h4>Specific Toxin / Ingestion:</h4>
    <input className="selector-form-3" type="text" name="clinDialyzableToxins" value={form3.clinDialyzableToxins}  onChange={handleChange3} id="clinDialyzableToxins" required/>
    </label>
    
    </>

    :

    null

    }


    <label className="flex-ctr-ctr-space-left" htmlFor="clinOtherElectrolytes"> 
    <h4>Other Electrolytes:</h4>
    {/* <h6>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</h6> */}
    <div className="tooltip-wrap">
        <AiOutlineExclamationCircle className="flex-ctr-ctr tool-tip-icon"/>
        <div className="tooltip-content">
            <p>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</p>
        </div>
    </div>
    <input className="selector-form-3" type="text" name="clinOtherElectrolytes" value={form3.clinOtherElectrolytes}  onChange={handleChange3} id="clinOtherElectrolytes"/>
    
    </label>

    <label className="flex-ctr-ctr-space-left" htmlFor="clinOtherClinFindings"> 
    <h4>Other Clinical Findings with Potential Therapeutic Implications:</h4>
    {/* <h6>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</h6> */}
    <div className="tooltip-wrap">
        <AiOutlineExclamationCircle className="flex-ctr-ctr tool-tip-icon"/>
        <div className="tooltip-content">
            <p>Note that this input field will not be factored into expert algorithm but may be considered by OpenAI</p>
        </div>
    </div>
    <input className="selector-form-3" type="text" name="clinOtherClinFindings" value={form3.clinOtherClinFindings}  onChange={handleChange3} id="clinOtherClinFindings"/>

    </label>

    <br></br>

    <button type="submit"><h3>Generate Consult Note</h3></button>

    </div>
    </div>
    </form>
    
    </div>
    );
}


