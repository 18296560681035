// import { FaMailBulk } from "react-icons/fa"
// import "./Footer.css"


// export default function Footer() {
//     return (
//         <footer className="flex-ctr-ctr">
//             <h4>Need help? Contact us!</h4>
//             <div className="flex-ctr-ctr">
//                 <a href="mailto:akiconsultapp@gmail.com">
//                     <FaMailBulk className="social-media-icon flex-ctr-ctr" />
//                 </a>
//             </div>
//         </footer>
//     )
// }

import { FaMailBulk } from "react-icons/fa"
import "./Footer.css"


export default function Footer() {
    return (
        <footer className="flex-ctr-ctr">

        <h6 className="footer-font"><i>AKI Consult</i> &nbsp;</h6>
        <h6 className="footer-font">&#169; 2023 &nbsp;</h6>
        <h6 className="footer-font">Renal Consults &nbsp;</h6>
        
            <div className="flex-ctr-ctr">
            <div className="footer-contact-info button flex-ctr-ctr">
                <h6 className="footer-font">Email &nbsp;</h6>
                <a className="footer-contact-info" href="mailto:akiconsultapp@gmail.com">
                    <FaMailBulk className="social-media-icon flex-ctr-ctr" />
                </a>
                </div>
            </div>
        </footer>
    )
}