import { deletePrecipitant } from "../../utilities/cases-service"

export default function PrecipitantItem ({precipitant, caseid, handleRequest}) {

    async function handleDelete() {
        await deletePrecipitant(caseid, precipitant._id)
        handleRequest()
    }

    return (
        <tr>
            <td>{precipitant.type}</td>
            <td>{precipitant.specific}</td>
            <td>{precipitant.weight}</td>
            <td><button onClick={handleDelete}><h4>Remove</h4></button></td>
        </tr>
    )
}