import { useState } from "react"
import { updateUser } from "../../utilities/users-service"
import { useNavigate } from "react-router-dom";
import { logOut } from "../../utilities/users-service";
import "./EditUserPage.css"


export default function EditUserPage({user, setUser}) {

    const navigate = useNavigate()

    async function handleSubmit(evt) {
        try {
            evt.preventDefault()
            const { name, email } = formData
            const newUserData = { name, email }
            await updateUser(user._id, formData)
            logOut()
            setUser(null)
            navigate(`/aki`)
        }catch (error) {
            console.log(error)
        }

    }
    async function handleChange(evt) {
        const newData = {...formData, [evt.target.name]: evt.target.value}
        setFormData(newData)
    }

    const [formData, setFormData] = useState({
        name: user.name,
        email: user.email,
        confirm: ""
    })

    const disable = formData.email !== formData.confirm;


    return (
        <div className="edit-user-ctr">
            <div className="banner">
                <h1>Edit Profile Information</h1>
            </div>
            <div className="flex-ctr-ctr">
              <form className="edit-user-form"autoComplete="off" onSubmit={handleSubmit}>
                <label>Name</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                <label>Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                <label>Confirm Email</label>
                <input type="email" name="confirm" value={formData.confirm} onChange={handleChange} required />
                <div></div>
                <h6><span>you will be required to login again after updating your profile information</span></h6>
                <div></div>
                <button col-span="2" type="submit" disabled={disable}><h3>Update</h3></button>
              </form>
            </div>
          </div>
    )
}