
import * as casesAPI from './cases-api'


export async function getCases(){
    try {
        const data = await casesAPI.index()
        return data
    }catch(err){
        return err
    }
}
export async function createCase(newCaseData){

    try {
        const data = await casesAPI.create(newCaseData)
        return data
    }catch(err){
        return err
    }
}

export async function deletePrecipitant(caseid, precipitantid) {
    try {
        // console.log("case-service deleting precip")
        const resp = await casesAPI.deletePrecipitant(caseid, precipitantid)
        return resp
    } catch(err) {
        return err
    }
}

export async function createPrecipitant(id, precipitantData){
    try {
        const data = await casesAPI.createPrecipitant(id, precipitantData)
        return data
    }catch(err){
        return err
    }
}

export async function getCase(id){
    try{
       const data = await casesAPI.detail(id)
       return data
    }catch(err){
        return err
    }
}


export async function getMostRecentCase(id){
    try{
       const data = await casesAPI.getMostRecentCase(id)
       return data
    }catch(err){
        return err
    }
}

export async function deleteCase(id){
    try{
       const data = await casesAPI.destroy(id)
       return data
    }catch(err){
        return err
    }
}

export async function updateCase(id, data){
    try{
       const resp = await casesAPI.update(id, data)
       return resp
    }catch(err){
        return err
    }
}

export async function getOpenAIImpression(id){
    try{
       const resp = await casesAPI.getOpenAIImpression(id)
       return resp
    }catch(err){
        return err
    }
}
export async function getOpenAIDiagnostics(id){
    try{
       const resp = await casesAPI.getOpenAIDiagnostics(id)
       return resp
    }catch(err){
        return err
    }
}
export async function getOpenAITherapeutics(id){
    try{
       const resp = await casesAPI.getOpenAITherapeutics(id)
       return resp
    }catch(err){
        return err
    }
}