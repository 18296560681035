export default {
    imaging: false,
    hydronephrosis: 0,
    proteinuria: -1,
    priorProteinuria: false,
    hematuria: -1,
    priorHematuria: false,
    thrombocytopenia: 0,
    hemolysis: 0,
    casts: -1,
    castsMuddyBrown: false,
    castsHyaline: false,
    castsWhiteBloodCell: false,
    castsRedBloodCell: false,
    clinUremia: 0,
    clinEdema: 0,
    clinPulmEdema: 0,
    clinUrineOutput: 0,
    clinUrineOutputRefractory: 0,
    clinVolumeChallengeGiven: 0,
    clinImprovedCreatWithVolume: 0,
    clinLowECBV: 0,
    clinHyperkalemia: false,
    clinMetabolicAcidosis: false,
    clinDialyzableToxinPresent: false
}