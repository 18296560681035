import sendRequest from "./send-request";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function index() {
  return sendRequest(`${BASE_URL}/cases`, "GET")
}

export async function create(data) {
  return sendRequest(`${BASE_URL}/cases`, "POST", data)
}


export async function getMostRecentCase() {
  return sendRequest(`${BASE_URL}/cases/most-recent`, "GET")
}

export async function detail(id) {
  return sendRequest(`${BASE_URL}/cases/${id}`, "GET")
}

export async function destroy(id) {
  return sendRequest(`${BASE_URL}/cases/${id}`, "DELETE")
}

export async function update(id, data) {
  return sendRequest(`${BASE_URL}/cases/${id}`, "PUT", data)
}

export async function createPrecipitant(id, data) {
  return sendRequest(`${BASE_URL}/cases/${id}/precipitants`, "POST", data)
}

export async function deletePrecipitant(caseid, precipitantid) {
  return sendRequest(`${BASE_URL}/cases/${caseid}/precipitants/${precipitantid}`, "DELETE")
}

export async function getOpenAIImpression(id) {
  return sendRequest(`${BASE_URL}/cases/${id}/open-ai/impression`, "GET")
}

export async function getOpenAIDiagnostics(id) {
  return sendRequest(`${BASE_URL}/cases/${id}/open-ai/diagnostics`, "GET")
}

export async function getOpenAITherapeutics(id) {
  return sendRequest(`${BASE_URL}/cases/${id}/open-ai/therapeutics`, "GET")
}