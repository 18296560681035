
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import { useState } from "react";
import LoginForm from "../../components/LoginForm/LoginForm";
import "./AuthPage.css"
import { Link } from "react-router-dom";

export default function AuthPage ({ setUser }) {
    const [showSignUp, setShowSignUp] = useState(false)
    
    function handleAuthClick() {
        showSignUp ? setShowSignUp(false) : setShowSignUp(true)
    }

return (
    <>
    <nav>
        <Link className="link" to="/aki">
            <button className="nav-link"><h4>Home</h4></button>
        </Link>
    </nav>
    <div className="auth-page-ctr flex-ctr-ctr-col">
        <h1 className="renal-consults-text">RenalConsults.com</h1>
        <h4 className="renal-consults-text">CONSULT NOTE GENERATOR</h4>
        <div className="flex-ctr-ctr login-signup-toggle-ctr">
            <p> { showSignUp ? "Already a user?" : "Sign up today!"} </p>
            <button onClick={handleAuthClick}>{ showSignUp ? "Log in" : "Sign Up" }</button>
        </div>
        {showSignUp ?
        <SignUpForm setUser={setUser} />
        :
        <LoginForm setUser={setUser} />
        }
    </div>
    </>

);
}